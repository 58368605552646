import { useEffect, useLayoutEffect, useState } from "react";
import gsap from "gsap";
import NU from "../../shared/Comps/IconNearU";


export function Header() {
    const menuItems = [
        { id: 'description', title: '¿Qué es NearU?', icon: '../../../public/icons/menuIcons/help.svg'},
        { id: 'cards_section', title: 'Lo mejor', icon: '../../../public/icons/menuIcons/search.svg'},
        { id: 'contacto', title: 'Contáctanos', icon: '../../../public/icons/menuIcons/business.svg'},
    
      ];
    const [activeSection, setActiveSection] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            const t1 = gsap.timeline();
            t1.from("#menu_logo", {
                opacity: 0,
                duration: 0.5,
                delay: 0.2,
            }).from("#menu_item", {
                opacity: 0,
                yPercent: "-100",
                stagger: 0.2,
            }).to("#menu_item", {
                opacity: 1,
                yPercent: '0',
            }).to("#menu_logo", {
                opacity: 1,
            })
        })
        return () => ctx.revert()
    }, []);

    return (
            isMobile ? 
            <div id="navbar_mobile" className={`p-2 w-100 d-flex flex-column position-fixed bottom-0`}>
                <div className={`d-flex justify-content-between bg-dark px-4 py-3`} style={{borderRadius: 60}}>
                    {menuItems.map((item, id) => (
                        <div key={id}>
                            <a 
                                className={`text-light text-decoration-none d-flex align-items-center flex-column ${activeSection === item.title ? 'text-decoration-underline' : ''}`} 
                                href={`#${item.id}`}
                                onClick={() => setActiveSection(item.title)}

                                >
                                {/* <img className="mb-1" style={{width:'1.6rem'}} src={item.icon} alt="" /> */}
                                <b className="m-0 p-0" style={{fontSize: '.8rem'}}>{item.title}</b>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
            :
            <div style={{
                background: 'linear-gradient(#000, transparent)',
                zIndex: 3
            }} className={`p-4 w-100 d-flex justify-content-between position-fixed align-items-center`}>
                <a href="/">
                    <NU/>
                </a>
                <div className={`d-flex`}>
                    {menuItems.map((item, id) => (
                        <div id="menu_item" className="mx-1" key={id}>
                            <b>
                                <a 
                                    className={`text-decoration-none ${activeSection === item.title ? 'selected' : 'menu-item'}`} 
                                    href={`#${item.id}`}
                                    onClick={() => setActiveSection(item.title)}
                                    >
                                    {item.title}
                                </a>
                            </b>
                        </div>
                    ))}
                </div>
            </div>
    );
}
