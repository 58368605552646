import './App.css';
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Header } from './Sections/Header/Header';
import { useEffect, useState } from 'react';
import { Analytics } from "@vercel/analytics/react";
import ClickSpark from './shared/Comps/ClickSpark';
import { Landing } from './Pages/Landing';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Importa React Router
import { PrivacyPolicy } from './Pages/Legal/PrivacyPolicy'; // Importa el componente About
import { Footer } from './Sections/Footer/Footer'
import { TermsConditions } from './Pages/Legal/TermsConditions';

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Router>
      <div className="App">
        <Analytics />
        <SpeedInsights />
        <ClickSpark
          sparkColor='#fff'
          sparkSize={10}
          sparkRadius={15}
          sparkCount={8}
          duration={400}
        >
          <Header />
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsConditions />} />
          </Routes>
          <Footer></Footer>
        </ClickSpark>
      </div>
    </Router>
  );
}

export default App;