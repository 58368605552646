import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { FirstSection } from './Sections/FirstSection';
import { SecondSection } from './Sections/SecondSection';
import { ThirdSection } from './Sections/ThirdSection';
import { Chat } from './SubSections/Chat';
import { RotatingOptions } from './SubSections/RotatingOptions';
import { FirstMobileSection } from './Sections/FirstMobileSection';

gsap.registerPlugin(ScrollTrigger);

export function AppPresentation() {
    const sectionRef = useRef(null);
    const horizontalContainerRef = useRef(null);
    const itemsRef = useRef([]);
    const sectionsRef = useRef([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useLayoutEffect(() => {
        if (!isMobile) {
            const section = sectionRef.current;
            const horizontalContainer = horizontalContainerRef.current;
            const items = itemsRef.current;

            gsap.to(horizontalContainer, {
                x: () => -(horizontalContainer.scrollWidth - window.innerWidth),
                ease: "none",
                duration: 1,
                scrollTrigger: {
                    trigger: section,
                    start: "top top",
                    end: () => "+=" + (horizontalContainer.scrollWidth - window.innerWidth),
                    scrub: 1.5,
                    pin: true,
                    invalidateOnRefresh: true,
                },
            });

            items.forEach((item) => {
                gsap.fromTo(
                    item.querySelectorAll(".text-animation"),
                    { opacity: 0, y: 50 },
                    {
                        opacity: 1,
                        y: 0,
                        duration: 1,
                        scrollTrigger: {
                            trigger: item,
                            start: "left center",
                            end: "left center",
                            scrub: true,
                        },
                    }
                );
                gsap.fromTo(
                    item.querySelectorAll(".svg-animation"),
                    { opacity: 0, scale: 0.8 },
                    {
                        opacity: 1,
                        scale: 1,
                        duration: 1,
                        scrollTrigger: {
                            trigger: item,
                            start: "left center",
                            end: "left center",
                            scrub: true,
                        },
                    }
                );
            });

            return () => {
                ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
            };
        }
    }, [isMobile]);

    return (
        <>
            {isMobile ? (
                <div>
                    <div id='description' ref={(el) => (sectionsRef.current[0] = el)}>
                        <FirstMobileSection />
                    </div>
                    <div ref={(el) => (sectionsRef.current[1] = el)}
                        className="d-flex justify-content-center flex-column"
                        style={{ minHeight: "100vh", width: "100%" }}>
                        <h1 className="text-light" style={{ textAlign: "center", fontSize: "clamp(2rem, 6vw, 3rem)" }}>
                            Los mejores planes
                        </h1>
                        <h1 className="text-light" style={{ textAlign: "center", fontSize: "clamp(4rem, 8vw, 3.5rem)" }}>
                            cerca de ti
                        </h1>
                        <h1 className="text-light" style={{ textAlign: "center", fontSize: "clamp(2rem, 6vw, 3rem)" }}>
                            <span className="primaryColor">estés</span> donde <span className="primaryColor">estés</span>
                        </h1>
                    </div>
                    <div ref={(el) => (sectionsRef.current[2] = el)}>
                        <Chat />
                    </div>
                    <div ref={(el) => (sectionsRef.current[3] = el)}>
                        <RotatingOptions />
                    </div>
                </div>
            ) : (
                <div ref={sectionRef} style={{ width: "100%", height: "100vh", overflow: "hidden" }} id='description'>
                    <div ref={horizontalContainerRef} style={{ display: "flex", width: "max-content", height: "100%" }}>
                        <FirstSection ref={el => itemsRef.current[0] = el} />
                        <SecondSection ref={el => itemsRef.current[1] = el} />
                        <ThirdSection ref={el => itemsRef.current[2] = el} />
                    </div>
                </div>
            )}
        </>
    );
}
