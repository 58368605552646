import React from 'react';

function EventCard({ name, distance, image, type, musicGenres }) {

    const eventImage = image ? image : "/imgs/placeList/events/image1.png";

    return (
        <div id='event' className="card">
            <img src={eventImage} alt={name} className="image" />
            <div className="info">
                <div className='name-container'>
                    <p className='name'>{name}</p>
                </div>
                <p className='type'>{type}</p>
                <p className='distance'>{distance}</p>
            </div>
        </div>
    );
}

export default EventCard;

