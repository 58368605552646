import React, { useEffect, useState, useRef } from "react";
import './NoteSection.css';
import Note from './Note/Note.jsx';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import NearU from "../../shared/Comps/LogoNearU.jsx";

gsap.registerPlugin(ScrollTrigger);

const notesData = [
  { icon: "🔥", title: "Siempre hay algo que hacer", description: "NearU te recomienda eventos y lugares según tus gustos, asegurando que siempre encuentres algo interesante sin perder tiempo." },
  { icon: "🗺", title: "Explora sin límites", description: "Descubre lo mejor de tu ciudad o cualquier otro destino con información en tiempo real sobre los sitios más top." },
  { icon: "🗓️", title: "Organiza y disfruta", description: "Guarda eventos, crea tu propio calendario y mantente al día con todo lo que sucede a tu alrededor." },
];

export function NoteSection() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [currentNote, setCurrentNote] = useState(0);
  const sliderRef = useRef(null);
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    gsap.fromTo('.section-title', { opacity: 0, y: 50 }, { opacity: 1, y: 0, duration: 1, scrollTrigger: { trigger: '.section-title' } });
    gsap.fromTo('.note-card', { opacity: 0, y: 50 }, { opacity: 1, y: 0, duration: 1, stagger: 0.2 });
  }, [currentNote]);

  // Autoslide cada 5 segundos
  useEffect(() => {
    if (!isMobile) return;

    const interval = setInterval(() => {
      setCurrentNote((prev) => (prev + 1) % notesData.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [isMobile]);

  // Efecto de desplazamiento para el slider
  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.style.transform = `translateX(-${currentNote * 100}%)`;
    }
  }, [currentNote]);

  // Funciones para cambiar de nota en móvil
  const nextNote = () => setCurrentNote((prev) => (prev + 1) % notesData.length);
  const prevNote = () => setCurrentNote((prev) => (prev - 1 + notesData.length) % notesData.length);

  // Eventos de touch para arrastrar el slider
  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (touchStartX.current - touchEndX.current > 50) {
      // Deslizar hacia la izquierda (siguiente)
      nextNote();
    } else if (touchEndX.current - touchStartX.current > 50) {
      // Deslizar hacia la derecha (anterior)
      prevNote();
    }
  };

  return (
    <div id="cards_section" className="container d-flex flex-column justify-content-center align-items-center py-4" style={{minHeight: '100vh'}}>
      <h2 className="title text-white text-start">
        <b>Lo mejor de </b><NearU />
      </h2>

      <div className="mt-5 w-100">
        {isMobile ? (
          <div
            className="slider-container"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <div className="slider" ref={sliderRef}>
              {notesData.map((note, index) => (
                <div key={index} className="slide">
                  <Note title={note.title} description={note.description} icon={note.icon} />
                </div>
              ))}
            </div>
            <div className="slider-indicators">
              {notesData.map((_, index) => (
                <div
                  key={index}
                  className={`indicator ${index === currentNote ? 'active' : ''}`}
                  onClick={() => setCurrentNote(index)}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className="row">
            {notesData.map((note, index) => (
              <div key={index} className={`${index == 0 ? 'col-sm-12' : 'col-sm-6'} col-xl-4 mb-4 nu-text-secondary`}x>
                <Note title={note.title} description={note.description} icon={note.icon} />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}