import React from 'react';
import './ProfileCard.css';

function ProfileCard({ name, distance, image, type }) {
    const eventImage = image ? image : "/imgs/placeList/profiles/pacha.png";

    return (
        <div id='profile' className="card">
            <img src={eventImage} alt={name} className="image" />
                <div className='name-container py-2'>
                    <p className='name'>{name}</p>
                </div>
        </div>
    );
}

export default ProfileCard;

