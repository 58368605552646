

export function NearU({ classNames = "" }) {
    return (
        <span className={`text-light ${classNames}`}>
            <b className="invertU">U</b>
            <b>
                <span className="rotated-e">e</span>
                ar<span className="primaryColor">U</span>
            </b>
        </span>
    );
}

export default NearU;