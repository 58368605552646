import React from "react";
import { FaFacebook, FaTiktok, FaInstagram } from "react-icons/fa";
import NU from "../../shared/Comps/IconNearU";
import NearU from "../../shared/Comps/LogoNearU";

export function Footer() {
  return (
    <footer className="text-light py-4">
      <div className="container mb-5 pb-5">
        <div className="row text-center text-md-start">

          <div className="col-12 col-md-6 mb-4 mb-md-0 d-flex flex-column align-items-center align-items-md-start">
            <NU />
          </div>

          <div className="col-12 col-md-6 d-flex flex-column align-items-end">
            <ul className="list-unstyled d-flex gap-3">
              <li>
                <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer" className="text-white text-decoration-none fs-4">
                  <FaFacebook />
                </a>
              </li>
              <li>
                <a href="https://www.tiktok.com/" target="_blank" rel="noopener noreferrer" className="text-white text-decoration-none fs-4">
                  <FaTiktok />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer" className="text-white text-decoration-none fs-4">
                  <FaInstagram />
                </a>
              </li>
            </ul>

            <div className="mt-1 d-flex justify-content-end mb-2 gap-3">
              <a href="/privacy" className="text-secondary text-decoration-none">Política de privacidad</a>
              <a href="/terms" className="text-secondary text-decoration-none">Términos y condiciones</a>
            </div>
          </div>

        </div>
      </div>
      <NearU classNames="footer-watermark" />
    </footer>
  );
}

export default Footer;
