import { useEffect, useState } from "react";
import { Home } from "../Sections/Home/Home"
import { AppPresentation } from "../Sections/AppPresentation/AppPresentation"
import { NoteSection } from "../Sections/NoteSection/NoteSection"
import { Contact } from "../Sections/Contact/Contact";
import { Footer } from "../Sections/Footer/Footer"

export function Landing() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="landing">
            <Home isMobile={isMobile}></Home>
            <div className='web-content'>
                <AppPresentation />
                <NoteSection></NoteSection>
                <Contact></Contact>
            </div>
        </div>
    );
}