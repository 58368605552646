import React, { useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import LogoNearU from '../../../shared/Comps/LogoNearU'

gsap.registerPlugin(ScrollTrigger);

export function FirstMobileSection({ ref }) {
    const textRef = useRef(null);
    const imageRef = useRef(null);
    const carouselRef = useRef(null);
    const descriptionRef = useRef(null);
    const tag1Ref = useRef(null);
    const tag2Ref = useRef(null);
    const tag3Ref = useRef(null);
    const tag4Ref = useRef(null);


    useLayoutEffect(() => {
        const text = textRef.current;
        const image = imageRef.current;
        const description = descriptionRef.current;
        // const carousel = carouselRef.current;
        const tag1 = tag1Ref.current;
        const tag2 = tag2Ref.current;



        // Animación para el texto
        gsap.fromTo(text.querySelectorAll('.text-animation'),
            { scale: 0 },
            {
                scale: 1, // Se encoge hasta la mitad del tamaño
                ease: "bounce.out",
                duration: 1,
                scrollTrigger: {
                    trigger: text,
                    start: "top center", // Inicia el efecto cuando el div llega al tope de la pantalla
                },
            }
        );

        // // Animación para la imagen
        // gsap.fromTo(description,
        //     { opacity: 0, scale: 5, translateY: -100 },
        //     {
        //         scale: 1,
        //         opacity: 1,
        //         translateY: 150,
        //         ease: "expo.in",
        //         scrollTrigger: {
        //             trigger: description,
        //             start: "top top -20%",
        //         },
        //     }
        // );
    }, []);

    return (
        <div ref={ref} className='first-mobile-section d-flex align-items-center' style={{ width: '100vw', minHeight: '100vh' }}>
            <div className="first-inner-section" >
                <div className="container">

                    <div ref={textRef} className="first-text-section row">
                        <div className="pb-2 d-flex flex-column align-items-center col-12">
                            <h3 ref={tag1Ref} className="first-section-tag-1 text-animation">
                                ¿En una ciudad nueva?
                            </h3>
                            <h2 ref={tag4Ref} className="first-section-tag-2 text-animation">
                                ¿Aburrido de siempre lo mismo?
                            </h2>
                            <h3 ref={tag3Ref} className="first-section-tag-1 text-animation">
                                ¿Te pierdes eventos interesantes?
                            </h3>
                            <h2 ref={tag3Ref} className="first-section-tag-2 text-animation">
                                ¿No tienes plan este finde?
                            </h2>
                            <h3 ref={tag3Ref} className="first-section-tag-1 text-animation">
                                ¿Quieres descubrir lugares TOP cerca?
                            </h3>
                        </div>
                        <div ref={descriptionRef} className='col-12 d-flex justify-content-end p-0'>
                            <h3 className="first-section-description">
                                Si tu respuesta a alguna de estas preguntas es SÍ <LogoNearU></LogoNearU> es la App que llevas años buscando
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}