import React from 'react';
import RotatingText from '../../../shared/Comps/WordCarousel/RotatingText';

export const RotatingOptions = () => {
  return (
    <div className="word-carousel" style={{minHeight: '100vh'}}>
      <RotatingText
        texts={['Discotecas', 'Pubs', 'Eventos', 'Exposiciones', 'Bares', 'Conciertos', 'Museos', 'Festivales']}
        mainClassName="overflow-hidden justify-content-center d-flex"
        staggerFrom={"last"}
        initial={{ y: "100%" }}
        animate={{ y: 0 }}
        exit={{ y: "-120%" }}
        staggerDuration={0.025}
        splitLevelClassName="overflow-hidden pb-0.5 sm:pb-1 md:pb-1"
        transition={{ type: "spring", damping: 30, stiffness: 400 }}
        rotationInterval={2000}
      />
      <h2 className="text-light">Desde fiestas hasta museos.</h2>
      <h5 className="text-light">Todas las opciones de ocio de tu zona en una sola app.</h5>
    </div>
  );
};