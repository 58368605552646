import React, { useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import RotatingText from '../../../shared/Comps/WordCarousel/RotatingText';

gsap.registerPlugin(ScrollTrigger);

export function SecondSection() {
    const wordsRef = useRef([]);
    const circleRef = useRef(null);
    const sectionRef = useRef(null); // Referencia a la sección
    const tagsRef = useRef([]); // Referencia a los tags
    const optionsRef = useRef(null);

    useLayoutEffect(() => {
        const section = sectionRef.current; // Obtén la sección
        const circle = circleRef.current;
        const options = optionsRef.current;

        // Obtén el ancho de la sección
        const sectionWidth = section.offsetWidth;
        console.log("width second section: ", sectionWidth)


        gsap.fromTo(
            options,
            {
                opacity: 0,
            },
            {
                opacity: 1,
                color: 'white',
                scrollTrigger: {
                    start: 'right left',
                    trigger: section,
                    scrub: 1
                }
            }
        )

        // Animaciones para las palabras
        wordsRef.current.forEach((word, index) => {
            if (word) {
                gsap.fromTo(
                    word,
                    {
                        opacity: 0,
                        scale: index % 3 === 0 ? 0.5 : 1.2,
                        x: index % 2 === 0 ? -100 : 100,
                        y: index % 2 === 0 ? 50 : -50,
                        rotation: index % 2 === 0 ? 10 : -10,
                    },
                    {
                        opacity: 1,
                        scale: 1,
                        x: 0,
                        y: 0,
                        rotation: 0,
                        duration: 3,
                        ease: index % 3 === 0 ? "elastic.out(1, 0.5)" : "power2.out",
                        scrollTrigger: {
                            trigger: section,
                            start: "right -20%",
                        },
                    }
                );
            }
        });

        // 🌟 Primera animación: Caída y rebote
        gsap.fromTo(
            circle,
            {
                translateY: '-100vh'
            },
            {
                translateY: 0,
                duration: 1.5,
                ease: "bounce.out",
                duration: 1.5,
                scrollTrigger: {
                    trigger: section, // La sección completa como trigger
                    start: "right left", // Comienza cuando la sección entra en la vista
                    // end: `+=1000`,
                    // scrub: true, // Para evitar que se detenga con el scroll
                },
            }
        );

        // 🔥 Segunda animación: Rodar a la izquierda
        gsap.to(
            circle,
            {
                x: sectionWidth, // Se mueve completamente a la izquierda (basado en el ancho de la sección)
                duration: 1.5,
                scrollTrigger: {
                    trigger: section, // La sección completa como trigger
                    start: "right left", // Comienza cuando la sección entra en la vista
                    // end: `+=1000`,
                    // scrub:1.5,
                },
            }
        );

        // Animaciones para los tags
        tagsRef.current.forEach((tag, index) => {
            if (tag) {
                gsap.fromTo(
                    tag,
                    {
                        opacity: 0,
                        scale: 0,
                    },
                    {
                        opacity: 1,
                        scale: 1,
                        duration: .2,
                        ease: "expo.out",
                        scrollTrigger: {
                            trigger: tag,
                            start: "left -40%", // Comienza cuando el tag entra en la vista
                            toggleActions: "play none none",
                        },
                    }
                );
                gsap.to(
                    tag,
                    {
                        translateY: '150vh',
                        rotate: 15,
                        duration: 2,
                        ease: "power2.out",
                        scrollTrigger: {
                            trigger: tag,
                            start: "center center", // Comienza cuando el tag entra en la vista
                        },
                    }
                );
            }
        });

    }, []);

    const words = [
        "Encuentra", "planes", "cerca",
        "de", "tí", "estés", "donde", "estés"
    ];

    const enlighted = [
        false, false, false,
        false, false, true, false, true
    ];

    const tags = [
        "Discotecas", "Museos", "Bares", "Viajes", "Experiencias"
    ];

    return (
        <>

            <div ref={sectionRef} style={{ height: "100vh", width: 'fit-content', padding:'padding: 10rem', position: 'relative' }}>
                <div className='col-12 col-md-6 h-100 px-5 mx-5 d-flex align-items-center justify-content-center text-light gap-3 w-100'>
                    {words.map((word, index) => (
                        <h1 className={`${enlighted[index] ? 'primaryColor' : ''} text-explain`} key={index} ref={el => (wordsRef.current[index] = el)}>
                            {word}
                        </h1>
                    ))}
                </div>
                <svg ref={circleRef} className="" width="100" height="100" viewBox="0 0 100 100" style={{ position: 'absolute', left: 0, bottom: 0 }}>
                    <circle cx="50" cy="50" r="40" fill="#C6FF28" />
                </svg>
                <div ref={optionsRef} style={{ position: 'fixed', bottom: 0, left: 0, zIndex: 1000 }}>
                    <h1>
                        <RotatingText
                            texts={['discotecas', 'pubs', 'eventos', 'salas', 'bares', 'conciertos']}
                            mainClassName="px-2 sm:px-2 md:px-3 bg-cyan-300 text-black overflow-hidden py-0.5 sm:py-1 md:py-2 justify-center rounded-lg"
                            staggerFrom={"last"}
                            initial={{ y: "100%" }}
                            animate={{ y: 0 }}
                            exit={{ y: "-120%" }}
                            staggerDuration={0.025}
                            splitLevelClassName="overflow-hidden pb-0.5 sm:pb-1 md:pb-1"
                            transition={{ type: "spring", damping: 30, stiffness: 400 }}
                            rotationInterval={2000}
                        />
                    </h1>
                </div>


                {/* Tags colocados en diferentes partes de la pantalla */}
                {tags.map((tag, index) => (
                    <div
                        key={index}
                        ref={el => (tagsRef.current[index] = el)}
                        className={`options-tag options-tag-${index + 1}`} // Asigna la clase base y la específica
                    >
                        {tag}
                    </div>
                ))}

            </div>
        </>
    );
}