import React, { useState, useEffect } from 'react';

const conversations = [
  {
    id: 1,
    messages: [
      { sender: 'Juan', text: 'Oye, ¿ya sabes qué vamos a hacer en Barcelona este fin de semana? 😅' },
      { sender: 'Laura', text: 'Que va jaja. ¿Tú tienes idea?' },
      { sender: 'Juan', text: 'Nop, he visto una app que recomienda planes según la zona. ¿La probamos?' },
      { sender: 'Laura', text: '¡Sí, porfa! Así no nos perdemos lo mejor de la ciudad.' },
      { sender: 'Juan', text: 'Perfecto, ahí te paso el link. ¡Vamos a explorar! 🚀' },
    ],
  },
  {
    id: 2,
    messages: [
      { sender: 'Carlos', text: 'Broo, no quiero quedarme este finde en casa otra vez. 😴' },
      { sender: 'Pedro', text: 'Jaja, yo tampoco. ¿Qué tal si buscamos algo nuevo?' },
      { sender: 'Carlos', text: 'Buena idea. ¿Conoces alguna app que recomiende planes?' },
      { sender: 'Pedro', text: 'Sí, hay una app que te sugiere bares, conciertos... La descargo y te digo.' },
      { sender: 'Carlos', text: '¡Dale! Así no acabamos en el mismo bar de siempre. 🍻' },
    ],
  },
  {
    id: 3,
    messages: [
      { sender: 'Ana', text: 'Amor ¿Por qué no hacemos algo diferente este finde? 🥺' },
      { sender: 'Luis', text: 'Voy a mirar planes en la app que te recomendé' },
      { sender: 'Ana', text: '¡Sí! A ver qué ves.' },
      { sender: 'Luis', text: 'Anda, hay un taller de cocina italiana cerca. ¿Vamos?' },
      { sender: 'Ana', text: 'Ala que guay. ¡Reservemos ya! 🍝❤️' },
    ],
  },
];

export const Chat = () => {
  const [currentConversation, setCurrentConversation] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentConversation((prev) => (prev + 1) % conversations.length);
    }, 13000); // Cambia cada 10 segundos
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="chat-container" >
      {conversations[currentConversation].messages.map((msg, index) => (
        <div key={index} className={`chat-message ${msg.sender === 'Juan' || msg.sender === 'Carlos' || msg.sender === 'Ana' ? 'left' : 'right'}`}>
          <p className="text">{msg.text}</p>
        </div>
      ))}
    </div>
  );
};