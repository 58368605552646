import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import MouseMoveEffect from '../../Animations/MouseMoveEffect';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import PlaceList from './PlaceList/PlaceList';
import NearU from '../../shared/Comps/LogoNearU';

// Registra el plugin ScrollTrigger
gsap.registerPlugin(ScrollTrigger);

export function Home({ isMobile }) {
    const homeRef = useRef(null);
    const lineRef = useRef(null);
    const logoRef = useRef(null);
    const phraseRef = useRef(null);

    useEffect(() => {
        const element = homeRef.current;
        const line = lineRef.current;
        const logo = logoRef.current;
        const phrase = phraseRef.current;

        gsap.fromTo(
            logo,
            { 
                scale: 0.8,
                opacity: 0
            }, // Estado inicial
            {
                scale: 1, // Estado final
                opacity: 1, // Estado final
                ease: "power2.out",
                duration: 3, // Duración de la animación
            }
        );

        gsap.to(element, {
            scale: 0.5, // Se encoge hasta la mitad del tamaño
            opacity: 0, // Se desvanece completamente
            ease: "circ.inOut",
            scrollTrigger: {
                trigger: element,
                start: "top top", // Inicia el efecto cuando el div llega al tope de la pantalla
                end: "bottom top", // Termina cuando el div desaparece de la vista
                scrub: 1, // Hace que la animación sea fluida al scrollear
            },
        });


        gsap.fromTo(line,
            {
                width: "100%", // Estado final
            },
            {
                width: 0,
                ease: "power1.inOut",
                scrollTrigger: {
                    trigger: element,
                    start: "top top", // Inicia el efecto cuando el div llega al tope de la pantalla
                    end: "bottom top", // Termina cuando el div desaparece de la vista
                    scrub: 1, // Hace que la animación sea fluida al scrollear
                },
            });

        gsap.fromTo(
            line,
            { width: 0 }, // Estado inicial
            {
                width: "100%", // Estado final
                ease: "power1.inOut",
                duration: 2, // Duración de la animación
            }
        );

        gsap.fromTo(
            phrase,
            {
                opacity: 1, // Estado final
            },
            {
                opacity: 0,
                ease: "power1.inOut",
                scrollTrigger: {
                    trigger: element,
                    start: "top top", // Inicia el efecto cuando el div llega al tope de la pantalla
                    end: "bottom top", // Termina cuando el div desaparece de la vista
                    scrub: 1, // Hace que la animación sea fluida al scrollear
                },
            },
        );

    }, []);

    return (
        <div id='home' className="home">
            <div className="background">
                <div className="overlay"></div>
                <PlaceList></PlaceList>
            </div>
            <div className="content pb-4" ref={homeRef}>
                <div style={{ transform: isMobile ? 'translateY(-4rem)' : '' }}>
                    <div ref={logoRef} className='p-5 d-flex flex-column '>
                        <MouseMoveEffect sensitivity={isMobile ? 0 : -10}>
                            <h1 className="text-light m-0 home_title"><NearU /></h1>
                        </MouseMoveEffect>
                    </div>
                </div>
            </div>
            <div ref={phraseRef} className="home-footer p-3">
                <h4 className='text-light m-3 text-start'>Conecta con tu zona</h4>
                <div ref={lineRef} className="line"></div>
            </div>
        </div>
    );
}
