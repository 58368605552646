import React from 'react';
import NearU from '../../shared//Comps/LogoNearU';

export const Contact = () => {

  return (
    <div className='contact'>
      <div id="contacto" className="container vh-100 d-flex flex-column justify-content-center align-items-center text-light">
        <h2 className="title text-center"><b>Únete a <NearU /> antes que nadie</b></h2>
        <h4 className="contact-description text-center">Ya queda poco para que NearU sea una realidad y queremos que tú seas de los primeros en vivir la experiencia.</h4>

        <a href="mailto:holanearu@gmail.com" class="btn-submit flex items-center gap-2 px-4 py-2 rounded-full cursor-pointer">
          Contáctanos
        </a>

        <h4 className="contact-description text-center pt-4">O envía un correo a holanearu@gmail.com</h4>
      </div>
    </div>
  );
};

