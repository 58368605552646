import React, { useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Chat } from '../SubSections/Chat'; // Asegúrate de importar correctamente
import { RotatingOptions } from '../SubSections/RotatingOptions'; // Asegúrate de importar correctamente

gsap.registerPlugin(ScrollTrigger);

export function ThirdSection({ ref }) {
    const sectionRef = useRef(null);
    const divRef = useRef(null);
    const titleRef = useRef(null);
    const descriptionRef = useRef(null);

    useLayoutEffect(() => {
        const section = sectionRef.current;
        const svg = divRef.current;
        const title = titleRef.current;
        const description = descriptionRef.current;

        // Animación para el SVG
        gsap.fromTo(
            svg,
            { width: 100, height: 100, borderRadius: 100 },
            {
                width: '50%',
                height: '100vh',
                borderRadius: 0,
                ease: 'power1.out',
                duration: 3,
                scrollTrigger: {
                    trigger: section,
                    start: 'right center',
                    scrub: 2.5,
                },
            }
        );

        gsap.fromTo(
            svg,
            { opacity: 0 },
            {
                opacity: 1,
                duration: 0.1,
                ease: 'expo.in',
                scrollTrigger: {
                    trigger: section,
                    start: 'right center',
                },
            }
        );

        gsap.fromTo(
            title,
            { scale: 0 },
            {
                scale: 1,
                scrollTrigger: {
                    trigger: section,
                    start: 'right 20%',
                    scrub: true,
                },
            }
        );

        gsap.fromTo(
            description,
            { scale: 0 },
            {
                scale: 1,
                scrollTrigger: {
                    trigger: section,
                    start: 'right 20%',
                    end: '+=100',
                    scrub: true,
                },
            }
        );
    }, []);

    return (
        <div className="third-section" ref={ref}>
            <div ref={divRef} className="left-section-3">
                <Chat />
            </div>

            <div className="right-section-3">
                <RotatingOptions />
               
            </div>
        </div>
    );
}