import React from 'react';

function PlaceCard({ name, distance, image, type }) {
    const placeImage = image ? image : "/imgs/placeList/places/image1.png";

    return (
        <div id='place' className="card">
            <img src={placeImage} alt={name} className="image" />
            <div className="info">
                <div className='name-container'>
                    <p className='name'>{name}</p>
                </div>
                <p className='type'>{type}</p>
                <p className='distance'>a {distance} de tí</p>
            </div>
        </div>
    );
}

export default PlaceCard;

