import React, { useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import LogoNearU from '../../../shared/Comps/LogoNearU'


gsap.registerPlugin(ScrollTrigger);

export function FirstSection({ ref }) {
    const textRef = useRef(null);
    const imageRef = useRef(null);
    const carouselRef = useRef(null);
    const descriptionRef = useRef(null);
    const tag1Ref = useRef(null);
    const tag2Ref = useRef(null);
    const tag3Ref = useRef(null);
    const tag4Ref = useRef(null);


    useLayoutEffect(() => {
        const text = textRef.current;
        const image = imageRef.current;
        const description = descriptionRef.current;
        // const carousel = carouselRef.current;
        const tag1 = tag1Ref.current;
        const tag2 = tag2Ref.current;



        // Animación para el texto
        gsap.fromTo(text.querySelectorAll('.text-animation'),
            { scale: 0 },
            {
                scale: 1, // Se encoge hasta la mitad del tamaño
                ease: "bounce.out",
                duration: 1,
                scrollTrigger: {
                    trigger: text,
                    start: "top center", // Inicia el efecto cuando el div llega al tope de la pantalla
                },
            }
        );

        // Animación para la imagen
        gsap.fromTo(description,
            { opacity: 0, scale: 5, translateY: -100 },
            {
                scale: 1,
                opacity: 1,
                translateY: 50,
                ease: "expo.in",
                scrollTrigger: {
                    trigger: description,
                    start: "top top -20%",
                },
            }
        );

        // Animaciones para los tags
        const tagAnimations = [
            { element: tag1, rotate: '-15', start: "bottom" },
            // { element: tag1, translateY: '100vh', rotate: '-=35', start: "-=50", duration: 2 },
            { element: tag2, rotate: '15', start: "bottom" },
            // { element: tag2, translateY: '100vh', rotate: '+=35', start: "-=200", duration: 2 }
        ];

        tagAnimations.forEach(({ element, ...props }) => {
            gsap.to(element, {
                ease: "bounce.out",
                duration: props.duration || 1,
                scrollTrigger: {
                    trigger: element,
                    start: props.start,
                    scrub: false,
                    pin: false,
                    anticipatePin: 1
                },
                ...props
            });
        });


    }, []);

    return (
        <div ref={ref} className='first-section' style={{ width: '100vw' }}>
            <div className="first-inner-section">
                <div ref={textRef} className="first-text-section row">
                    <div className="pb-2 d-block col-12">
                        <h3 ref={tag1Ref} className="first-section-tag-1 text-animation">
                            ¿En una ciudad nueva?
                        </h3>
                        <h2 ref={tag4Ref} className="first-section-tag-2 text-animation">
                            ¿Aburrido de siempre lo mismo?
                        </h2>
                        <h3 ref={tag3Ref} className="first-section-tag-1 text-animation">
                            ¿Te pierdes eventos interesantes?
                        </h3>
                        <h2 ref={tag3Ref} className="first-section-tag-2 text-animation">
                            ¿No tienes plan este finde?
                        </h2>
                        <h3 ref={tag3Ref} className="first-section-tag-1 text-animation">
                            ¿Quieres descubrir lugares TOP cerca?
                        </h3>
                    </div>
                    <div ref={descriptionRef} className='col-12 d-flex justify-content-end'>
                        <h3 className="first-section-description">
                            Si te sientes identificado, <LogoNearU></LogoNearU> es la App que llevas años buscando
                        </h3>
                    </div>
                </div>
                <div ref={imageRef} className="custom-image-container d-none">
                    <img src={'imgs/description/movile.png'} alt="Mockup" />
                </div>
            </div>
        </div>
    );

}