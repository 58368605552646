import { useEffect, useRef } from "react";
import gsap from "gsap";

const MouseMoveEffect = ({ sensitivity = 100, children }) => {
  const ref = useRef(null);
  let timeout = useRef(null);

  useEffect(() => {
    const update = ({ x, y }) => {
      const el = ref.current;
      if (el) {
        gsap.to(el, {
          x: gsap.utils.mapRange(0, window.innerWidth, -sensitivity, sensitivity, x),
          y: gsap.utils.mapRange(0, window.innerHeight, -sensitivity, sensitivity, y),
          duration: 0.2,
          overwrite: "auto",
        });

        // Limpiar timeout si el usuario sigue moviendo el mouse
        clearTimeout(timeout.current);

        // Establecer timeout para volver a la posición original
        timeout.current = setTimeout(() => {
          gsap.to(el, { x: 0, y: 0, duration: 1, ease: "power2.out" });
        }, 50);
      }
    };

    window.addEventListener("pointermove", update);

    return () => {
      window.removeEventListener("pointermove", update);
      clearTimeout(timeout.current);
    };
  }, [sensitivity]);

  return <div ref={ref}>{children}</div>;
};

export default MouseMoveEffect;
