import React from "react";
import SpotlightCard from './SpotlightCard/SpotlightCard';
  

const Note = ({ title, description, icon }) => {
  return (
    <SpotlightCard className="custom-spotlight-card text-white h-100 col-12" spotlightColor="rgba(198, 255, 40, 0.2)">
      <div className="text-start">
        <h5 className="title">{title}</h5>
        <p className="description mb-0 py-3">{description}</p>
        <h4 className="icon mb-3 text-end">{icon}</h4>
      </div>
    </SpotlightCard>
  );
};

export default Note;
